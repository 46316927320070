<template>
  <div class="header">
    <div class="gradient">
      <img src="../../img/Rectangle1.png" alt="" />
    </div>
    <div class="header-title">
      <div class="title-left">
        <img src="../../img/logoAppIcon.png" alt="" />
        <span>Passport</span>
      </div>
      <div class="title-right">
        <span>Technology</span>
        <span>Price</span>
      </div>
    </div>
    <div class="header-content">
      <div class="content-left">
        <div class="left-title">
          <span>安全，重</span>
          <span>中之重</span>
        </div>
        <div class="left-article">
          For ambitious companies around the world, 
          Stripe makes moving money as simple, 
          borderless, and programmable as the 
          rest of For ambitious companies around 
          the world, Stripe makes moving money as simple, 
          borderless, and programmable as the rest of For 
          ambitious companies around the world, Stripe 
          makes moving money as simple, borderless, and 
          programmable as the rest of
        </div>
        <div class="left-btn">立即下载</div>
      </div>
      <div class="content-right">
        <img src="../../img/hero.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
@import "../../style/all.less";

.header {
  width: calc(~"100% - 177");
  position: relative;
  overflow-x: hidden;

  // 渐变
  .gradient {
    width: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 999;
    background-image: radial-gradient(107.03% 103.52% at 8.13% 10.24%, #D9D9D9 0%, rgba(217, 217, 217, 0.434017) 4.13%, rgba(217, 217, 217, 0.163179) 29.52%, rgba(217, 217, 217, 0) 69.52%, rgba(217, 217, 217, 0) 100%),
    radial-gradient(107.03% 103.52% at 8.13% 10.24%, #D9D9D9 0%, rgba(217, 217, 217, 0.434017) 4.13%, rgba(217, 217, 217, 0.163179) 29.52%, rgba(217, 217, 217, 0) 69.52%, rgba(217, 217, 217, 0) 100%);

    & > img:nth-child(1) {
      width: 5000px;
      height: 2000px;
      position: absolute;
      top: 20%;
      left: 7%;
      transform: translate(-50%, -50%);
      // transform-origin: left top;
      // transform: rotateZ(90deg);
      // animation: rotates 4s infinite ease-in;
    }
  }

  // 顶部nav
  .header-title {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .title-left {
      display: flex;

      img {
        margin: 0 16px 0 0;
      }

      span {
        font-family: 'Arial';
        font-style: normal;
        color: #fff;
      }
    }

    .title-right {
      margin: 0 83px 0 0;
      align-items: center;

      span {
        font-family: 'Arial';
        font-style: normal;
        font-size: 18px;
        color: #fff;
      }

      & > span:nth-child(2) {
        display: inline-block;
        margin: 0 0 0 62px;
      }
    }
  }

  // 顶部主区域
  .header-content {
    color: #fff;
    display: flex;
    position: relative;

    .content-left {
      .left-title {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 900;
      }

      .left-article {
        margin-top: 17px;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 15px;
        line-height: 24px;
      }

      .left-btn {
        margin: 20px 0 0;
        border: 2px solid #FFFFFF;
        border-radius: 6px;
        background: conic-gradient(from 53.8deg at 127.03% -347.54%, rgba(0, 0, 0, 0) 0deg, rgba(79, 79, 79, 0.311583) 148.6deg, rgba(228, 228, 228, 0.5) 166.02deg, #ffffff95 169.92deg, rgba(255, 255, 255, 0) 179.25deg, rgba(0, 0, 0, 0) 360deg);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Arial';
        font-style: normal;
        font-size: 17px;
      }
    }
  }
}

// pc
@media screen and (min-width:@one) {
  .header {
    .gradient {
      height: 723px;
    }

    .header-title {
      padding: 61px 0 0;

      .title-left {
        margin: 0 0 0 94px;

        img {
          width: 38px;
        }

        span {
          font-size: 32px;
        }
      }

      .title-right {
        display: flex;
      }
    }
  }

  .header-content {
    margin: 128px 0 0;

    .content-left {
      margin: 0 0 0 94px;

      .left-title {
        font-size: 52px;
      }

      .left-article {
        width: 481px;
      }

      .left-btn {
        height: 38px;
        width: 142.5px;
      }
    }

    .content-right {
      margin: -200px 0 0 130px;

      img {
        width: 1080px;
        height: 810.22px;
      }
    }
  }
}

// 中型机
@media screen and (max-width:@one) and (min-width:@two) {
  .header {
    .gradient {
      height: 799px;
    }

    .header-title {
      padding: 61px 0 0;

      .title-left {
        margin: 0 0 0 77px;

        img {
          width: 37px;
        }

        span {
          font-size: 32px;
        }
      }

      .title-right {
        display: flex;
      }
    }
  }

  .header-content {
    flex-direction: column;
    margin: 64px 0 0;

    .content-left {
      margin: 0 0 0 77px;

      .left-title {
        font-size: 52px;
      }

      .left-article {
        width: 505px;
      }

      .left-btn {
        height: 38px;
        width: 196px;
      }
    }

    .content-right {
      margin: -50px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 780px;
        height: 585px;
      }
    }
  }
}

// ipad
@media screen and (max-width:@two) and (min-width:@three) {
  .header {
    .gradient {
      height: 799px;
    }

    .header-title {
      padding: 67px 0 0;

      .title-left {
        margin: 0 0 0 77px;

        img {
          width: 37px;
        }

        span {
          font-size: 32px;
        }
      }

      .title-right {
        display: none;
      }
    }
  }

  .header-content {
    margin: 58px 0 0;
    flex-direction: column;

    .content-left {
      margin: 0 0 0 77px;

      .left-title {
        font-size: 52px;

        & > span:nth-child(2) {
          display: block;
        }
      }

      .left-article {
        width: 304px;
      }

      .left-btn {
        height: 38px;
        width: 196px;
      }
    }

    .content-right {
      margin: -50px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 780px;
        height: 585px;
      }
    }
  }
}

// 移动端
@media screen and (max-width:@three) {
  .header {
    .gradient {
      height: 770px;
    }

    .header-title {
      padding: 43.54px 0 0;

      .title-left {
        margin: 0 0 0 35.32px;

        img {
          width: 25.93px;
        }

        span {
          font-size: 22px;
        }
      }

      .title-right {
        display: none;
      }
    }
  }

  .header-content {
    flex-direction: column;
    margin: 25px 0 0;

    .content-left {
      margin: 0 0 0 56px;

      .left-title {
        font-size: 32px;
      }

      .left-article {
        width: 285px;
      }

      .left-btn {
        width: 144px;
        height: 29px;
      }
    }

    .content-right {
      margin: -20px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 496px;
        height: 372px;
      }
    }
  }
}
</style>