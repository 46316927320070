<template>
  <div id="app">
    <Header />
    <MiddleOne />
    <MiddleTwo />
    <Footer />
  </div>
</template>

<script>
import Header from "@/views/Header/Header.vue"
import MiddleOne from '@/views/MiddleOne/MiddleOne.vue'
import MiddleTwo from '@/views/MiddleTwo/MiddleTwo.vue'
import Footer from '@/views/Footer/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    MiddleOne,
    MiddleTwo,
    Footer
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #000;
}
</style>
