<template>
  <div class="middletwo">
    <div class="middletwo-top">
      <div class="top-left">
        <div class="top-left-title">
          You own your data
        </div>
        <div class="top-left-article">
          Passport can sync data via iCloud or Dropbox, 
          which is entirely inaccessible to us.We strictly 
          adhere to Apple's privacy requirements and do 
          not collect any private data from our users.
        </div>
      </div>
      <div class="top-right">
        <div>
          <img src="../../img/iCloud.png" alt="">
        </div>
        <div>
          <img src="../../img/GoogleDrive.png" alt="">
        </div>
        <div>
          <img src="../../img/SkyDrive.png" alt="">
        </div>
        <div>
          <img src="../../img/Dropbox.png" alt="">
        </div>
      </div>
    </div>
    <div class="middletwo-platform">
      <div>
        <img src="../../img/AppStore.svg" alt="">
      </div>
      <div>
        <div>更多平台</div>
      </div>
    </div>
    <div class="middletwo-list">
      <List v-for="(item,index) in list" :key="index" :item="item" />
    </div>
  </div>
</template>

<script>
import List from '@/components/List/List.vue'

export default {
  data () {
    return {
      list: [
        {
          type: 'Personal',
          price: 19.99,
          sku: 'per year',
          ul: [
            '7 Days Free Trial',
            'App Auto Fill',
            'Input Helper with AutoType',
            'Beta Channel Access'
          ]
        },
        {
          type: 'Business',
          price: 29.99,
          sku: 'per year',
          ul: [
            '7 Days Free Trial',
            'App Auto Fill',
            'Input Helper with AutoType'
          ]
        }
      ]
    }
  },
  components: {
    List
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
@import "../../style/all.less";

.middletwo {
  overflow-x: hidden;

  // 顶部nav
  .middletwo-top {
    display: flex;
    margin: 114px 0 0;

    .top-left {
      color: #fff;

      .top-left-title {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        margin: 0 0 29px;
      }

      .top-left-article {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }
    }

    .top-right {
      display: flex;
      align-items: center;
    }
  }

  // 中部按钮
  .middletwo-platform {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 82px 0 0;

    > div:nth-child(1) {
      img {
        width: 228px;
        height: 79.6px;
      }
    }

    > div:nth-child(2) {
      div {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        margin: -4px 40px 0 0;
        width: 130px;
        height: 57px;
        background: conic-gradient(from 53.8deg at 127.03% -347.54%, rgba(0, 0, 0, 0) 0deg, rgba(79, 79, 79, 0.311583) 148.6deg, rgba(228, 228, 228, 0.893947) 166.02deg, #FFFFFF 169.92deg, rgba(255, 255, 255, 0) 179.25deg, rgba(0, 0, 0, 0) 360deg);
        border: 3px solid #FFFFFF;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }
  }

  // 价目列表
  .middletwo-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

// pc
@media screen and (min-width:@one) {
  .middletwo {
    .middletwo-top {
      .top-left {
        margin: 0 0 0 109px;

        .top-left-title {
          line-height: 56px;
          width: 557px;
          font-size: 49px;
        }

        .top-left-article {
          width: 550px;
          font-size: 23px;
        }
      }

      .top-right {
        > div {
          margin: 0 0 0 140px;

          img {
            width: 143px;
          }
        }
      }
    }

    .middletwo-platform {
      flex-direction: row;
    }

    .middletwo-list {
      width: 702px;
      margin: 176px auto 0;
    }
  }
}

// 中型机
@media screen and (min-width:@two) and (max-width:@one) {
  .middletwo {
    .middletwo-top {
      flex-direction: column;

      .top-left {
        margin: 0 0 0 109px;
        
        .top-left-title {
          line-height: 56px;
          width: 557px;
          font-size: 49px;
        }

        .top-left-article {
          width: 500px;
          font-size: 23px;
        }
      }

      .top-right {
        width: 350px;
        height: 350px;
        margin: 100px auto 0;
        justify-content: space-between;
        align-content: space-between;
        flex-wrap: wrap;

        > div:nth-child(2n - 1) {
          margin: 0 100px 0 0;
        }

        > div:nth-child(1) {
          margin: 0 100px 20px 0;
        }

        > div {
          margin: 0;

          img {
            width: 123px;
          }
        }
      }
    }

    .middletwo-platform {
      flex-direction: row;
    }

    .middletwo-list {
      width: 702px;
      margin: 136px auto 0;
    }
  }
}

// ipad
@media screen and (min-width:@three) and (max-width:@two) {
  .middletwo {
    .middletwo-top {
      flex-direction: column;

      .top-left {
        margin: 0 0 0 82px;

        .top-left-title {
          line-height: 56px;
          width: 321px;
          font-size: 49px;
        }

        .top-left-article {
          width: 313.13px;
          font-size: 23px;
        }
      }

      .top-right {
        width: 350px;
        height: 350px;
        margin: 100px auto 0;
        justify-content: space-between;
        align-content: space-between;
        flex-wrap: wrap;

        > div:nth-child(2n - 1) {
          margin: 0 100px 0 0;
        }

        > div:nth-child(1) {
          margin: 0 100px 20px 0;
        }

        > div {
          margin: 0;

          img {
            width: 115px;
          }
        }
      }
    }

    .middletwo-platform {
      flex-direction: column;

      > div:nth-child(2) {
        padding: 50px 0 0 35px;
      }
    }

    .middletwo-list {
      width: 341px;
      margin: 136px auto 0;
    }
  }
}

// 移动端
@media screen and (max-width:@three) {
  .middletwo {
    .middletwo-top {
      flex-direction: column;

      .top-left {
        margin: 0 0 0 56px;

        .top-left-title {
          line-height: 37px;
          width: 266px;
          font-size: 32px;
        }

        .top-left-article {
          width: 260px;
          font-size: 15px;
        }
      }

      .top-right {
        width: 280px;
        height: 280px;
        margin: 100px auto 0;
        justify-content: space-between;
        align-content: space-between;
        flex-wrap: wrap;

        > div:nth-child(2n - 1) {
          margin: 0 100px 0 0;
        }

        > div:nth-child(1) {
          margin: 0 100px 20px 0;
        }

        > div {
          margin: 0;

          img {
            width: 90px;
          }
        }
      }
    }

    .middletwo-platform {
      flex-direction: column;

      > div:nth-child(2) {
        padding: 50px 0 0 35px;
      }
    }

    .middletwo-list {
      width: 341px;
      margin: 118px auto 0;
    }
  }
}
</style>