<template>
  <div class="list">
    <div class="list-type">{{ item.type }}</div>
    <div class="list-price">${{ item.price }}</div>
    <div class="list-sku">{{ item.sku }}</div>
    <div class="list-ul">
      <ul>
        <li v-for="(li, index) in item.ul" :key="index">{{ li }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
.list {
  width: 341px;
  height: 568px;
  background-color: #1e1e1e;
  margin: 0 0 20px 0; // 后面可以调节
  color: #fff;

  .list-type {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0 50px;
  }

  .list-price {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 900;
    font-size: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-sku {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 51px 0 0;
  }

  .list-ul {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 54px 0 0 20px;

    ul {
      li {
        margin: 10px 0 0;
      }
    }
  }
}
</style>