<template>
  <div class="middleone">
    <div class="middleone-title">
      Works on mobile and in modern browsers
    </div>
    <div class="middleone-article">
      also works on your iOS devices, syncing all your encrypted information consistently and quickly.
    </div>
    <div class="middleone-banner">
      <img width="38.62" src="../../img/iOS.svg" alt="">
      <img width="31.81" src="../../img/Windows.svg" alt="">
      <img width="42.21" height="47.25" src="../../img/Android.svg" alt="">
      <img width="38.62" src="../../img/iOS-1.svg" alt="">
    </div>
    <div class="middleone-show">
      <div class="show-item">
        <img src="../../img/Windows.png" alt="">
      </div>
      <div class="show-item">
        <img src="../../img/Android.png" alt="">
      </div>
      <div class="show-item">
        <img src="../../img/macOS.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
@import "../../style/all.less";

.middleone {
  width: 100%;
  background-color: #000;
  overflow-x: hidden;

  // 顶部大标题
  .middleone-title {
    color: #fff;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
  }

  // 顶部小文章
  .middleone-article {
    color: #fff;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  // 顶部商标
  .middleone-banner {
    display: flex;
    align-items: center;

    & > img:nth-child(3) {
      margin: 0 88.38px -22px 0;
    }
  }

  // 图片区
  .middleone-show {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 75px;

    & > .show-item:nth-child(1) {
      margin: -30px 50px 0 0;

      img {
        height: 470px;
      }
    }

    & > .show-item:nth-child(2) {
      img {
        width: 340px;
        height: 440px;
      }
    }

    & > .show-item:nth-child(3) {
      img {
        width: 758px;
        height: 610px;
      }
    }
  }
}

// pc
@media screen and (min-width:@one) {
  .middleone {
    .middleone-title {
      margin-left: 100px;
      margin-top: 0;
      width: 743px;
      font-size: 49px;
      line-height: 56px;
    }

    .middleone-article {
      margin: 32px 0 0 100px;
      font-size: 23px;
      width: 550px;
    }

    .middleone-banner {
      margin: 53px 0 0 100px;

      img {
        margin-right: 88.38px;
      }
    }

    .middleone-show {
      margin-left: 0;

      & > .show-item:nth-child(2) {
        margin: 0;
      }

      & > .show-item:nth-child(3) {
        margin: -170px 0 0;
      }
    }
  }
}

// 中型机
@media screen and (max-width:@one) and (min-width:@two) {
  .middleone {
    .middleone-title {
      margin-left: 80px;
      margin-top: 50px;
      width: 523px;
      font-size: 49px;
      line-height: 56px;
    }

    .middleone-article {
      width: 387px;
      margin: 32px 0 0 80px;
      font-size: 23px;
    }

    .middleone-banner {
      margin: 53px 0 0 80px;

      img {
        margin-right: 88.38px;
      }
    }

    .middleone-show {
      margin-left: 0;

      & > .show-item:nth-child(2) {
        margin: 0;
      }

      & > .show-item:nth-child(3) {
        margin: -170px 0 0;
      }
    }
  }
}

// ipad
@media screen and (max-width:@two) and (min-width:@three) {
  .middleone {
    .middleone-title {
      margin-left: 80px;
      margin-top: 50px;
      width: 332px;
      font-size: 49px;
      line-height: 56px;
    }

    .middleone-article {
      width: 246px;
      margin: 32px 0 0 80px;
      font-size: 23px;
    }

    .middleone-banner {
      margin: 53px 0 0 80px;

      img {
        margin-right: 60px;
      }
    }

    .middleone-show {
      margin-left: -580px;

      & > .show-item:nth-child(2) {
        margin: 0 -60px -170px 0;
      }

      & > .show-item:nth-child(3) {
        margin: 0;
      }
    }
  }
}

// 移动端
@media screen and (max-width:@three) {
  .middleone {
    .middleone-title {
      margin-left: 56px;
      margin-top: 50px;
      width: 280px;
      font-size: 32px;
      line-height: 37px;
    }

    .middleone-article {
      width: 256px;
      margin: 32px 0 0 56px;
      font-size: 15px;
    }

    .middleone-banner {
      margin: 53px 0 0 56px;

      img {
        margin-right: 60px;
      }
    }
    
    .middleone-show {
      margin-left: -600px;

      & > .show-item:nth-child(2) {
        margin: 0 -60px -170px 0;
      }

      & > .show-item:nth-child(3) {
        margin: 0;
      }
    }
  }
}
</style>