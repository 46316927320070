<template>
  <div class="footer">
    <div class="footer-cr">
      Copyright © 2021 Passport. All rights reserved.
    </div>
    <div class="footer-logo">
      <img src="../../img/logoAppIcon.png" alt="">
    </div>
    <div class="footer-shadow"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
@import "../../style/all.less";
.footer {
  color: #fff;
  position: relative;

  .footer-cr {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center;
    opacity: 0.5;
  }

  .footer-logo {
    display: flex;
    justify-content: center;
    margin: 38px 0 0;
    padding: 0 0 115px;
  }
  
  background: radial-gradient(65% 110% at 50% 134.51%, #D9D9D9 0%, rgba(217, 217, 217, 0.19836) 67.55%, rgba(217, 217, 217, 0) 100%);
}

// pc
@media screen and (min-width:@one) {
  .footer {
    .footer-cr {
      margin: 104px 0 0;
      font-size: 19px;
    }

    .footer-logo {
      img {
        width: 56px;
      }
    }
  }
}

// 中型机
@media screen and (min-width:@two) and (max-width:@one) {
  .footer {
    .footer-cr {
      margin: 114px 0 0;
      font-size: 19px;
    }

    .footer-logo {
      img {
        width: 56px;
      }
    }
  }
}

// ipad
@media screen and (min-width:@three) and (max-width:@two) {
  .footer {
    .footer-cr {
      margin: 104px 0 0;
      font-size: 19px;
    }

    .footer-logo {
      img {
        width: 56px;
      }
    }
  }
}


// 移动端
@media screen and (max-width:@three) {
  .footer {
    .footer-cr {
      margin: 47px 0 0;
      font-size: 12px;
    }

    .footer-logo {
      img {
        width: 43px;
      }
    }
  }
}
</style>